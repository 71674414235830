
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import {formatWithPrecision} from '../../utils/format'
import {
  treeStructure,
  dataForResponse,
  headerStructure,
  rowStructure,
  columnStructure,
  fieldStructure
} from "../../models/reports/masterquery";

export default Vue.extend({
  name: "MasterQuery",
  props: {},
  data: () => ({
    formatWithPrecision,
    search: "",
    rows: [] as rowStructure[],
    loading: false,
    regions: [],
    countries: [],
    gnis: [],
    years: [],
    filters: {
      region: [],
      country: [],
      gni: [],
      years: [],
    },
    tree: [] as treeStructure[],
    selection: [] as any [],
    auxSelection: []
  }),
  computed: {
    checkAllTraslate() {
      return i18n.t("reports.masterquery.checkAll") || "All";
    },
    headers() {
      let header = [] as headerStructure[];
      if (this.rows.length > 0) {
        this.rows[0].columns.forEach((item: columnStructure) => {
          header.push({
            text: item.fieldName,
            align: "center",
            value: item.fieldValue,
          });
        });
      }
      return header;
    },
  },
  created() {
    this.loadFields();
    this.loadFilterData();
  },
  methods: {
    onResetFilters() {
      this.filters.region = [];
      this.filters.country = [];
      this.filters.gni = [];
      this.filters.years = [];
    },
    deleteFilter(filter: string, item: number) {
      switch (filter) {
        case "region": {
          const index = this.filters["region"].findIndex(
              (element: number) => element === item
          );
          this.filters["region"].splice(index, 1);
          return;
        }
        case "country": {
          const index = this.filters["country"].findIndex(
              (element: number) => element === item
          );
          this.filters["country"].splice(index, 1);
          return;
        }
        case "gni": {
          const index = this.filters["gni"].findIndex(
              (element: number) => element === item
          );
          this.filters["gni"].splice(index, 1);
          return;
        }
        case "years": {
          console.log(item);
          console.log(this.filters["years"]);
          const index = this.filters["years"].findIndex(
              (element: number) => element === item
          );
          this.filters["years"].splice(index, 1);
          return;
        }
      }
    },
    createTree(elements: any) {
      const rootName = this.checkAllTraslate + "";
      let root = {
        id: "root",
        name: rootName,
        isChecked: false,
        children: [] as treeStructure[],
      };
      elements.forEach((item: dataForResponse) => {
        root.children.push(this.recursiveTree(item));
      });
      this.tree.push(root);
    },
    recursiveTree(item: dataForResponse) {
      let element: treeStructure = {
        id: item.id,
        name: item.name,
        isChecked: item.isChecked,
        children: item.fields ? item.fields : ([] as treeStructure[]),
      };
      if (item.fields && item.fields.length > 0) {
        item.fields.forEach((field: treeStructure) => {
          if (field.isChecked) {
            this.selection.push(field.id)
          }
        })
      }
      if (item.nodes && item.nodes.length > 0) {
        item.nodes.forEach((son: dataForResponse) => {
          element.children.push(this.recursiveTree(son));
        });
      }
      return element;
    },
    loadFields() {
      this.loading = true;
      axios
          .get("MasterQuery/Fields")
          .then((res) => {
            this.createTree(res.data);
            this.loading = false;
          })
          .catch((err) => console.log(err));
    },
    conformParamsField() {
      let fieldIds: number[] = this.selection;
      return fieldIds;
    },
    loadData() {
      this.loading = true;
      axios
          .post("MasterQuery/Data", {
            fields: this.conformParamsField(),
            regions: this.filters.region,
            countries: this.filters.country,
            gnis: this.filters.gni,
            years: this.filters.years,
          })
          .then((res: any) => {
            this.rows = res.data;
            this.loading = false;
          })
          .catch((err) => console.log(err));
    },
    exportReport() {
      this.loading = true;
      axios
          .post(
              "MasterQuery/Data/export",
              {
                fields: this.conformParamsField(),
                regions: this.filters.region,
                countries: this.filters.country,
                gnis: this.filters.gni,
                years: this.filters.years,
              },
              {responseType: "blob"}
          )
          .then((res) => {
            this.loading = false;
            const fileName = `${i18n.t("reports.masterquery.title")}`;
            this.downloadExcel(fileName, res.data);
          })
          .catch((err) => {
                this.loading = false;
                console.log(err)
              }
          );
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], {type: "aplication/xlsx"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
    loadFilterData() {
      this.loadRegions();
      this.loadGNI();
      this.loadCountries();
      this.loadYears();
    },
    loadRegions() {
      axios
          .get("MasterQuery/regions")
          .then((res) => {
            this.regions = res.data;
          })
          .catch((err) => console.log(err));
    },
    loadGNI() {
      axios
          .get("MasterQuery/gnis")
          .then((res) => {
            this.gnis = res.data;
          })
          .catch((err) => console.log(err));
    },
    loadCountries() {
      axios
          .get("MasterQuery/countries")
          .then((res) => {
            this.countries = res.data;
          })
          .catch((err) => console.log(err));
    },
    loadYears() {
      axios
          .get("MasterQuery/years")
          .then((res) => {
            this.years = res.data;
          })
          .catch((err) => console.log(err));
    },
  }
});
